<template>
  <validation-observer
    ref="optionObserver"
    slim
  >
    <div class="mt-2 container-fluid">
      <b-row class="p-1">
        <b-col
          cols="4"
          class="pr-1"
        >
          <b-row>
            <b-col>
              <label class="field-name-class">
                {{ $t("views.playground.index.photo") }}
              </label>
              <div class="product-box">
                <div class="product-img">
                  <img
                    :src="optionPhoto"
                    class="option-img"
                  >
                  <div class="product-hover">
                    <ul>
                      <li
                        v-if="optionPhoto === svgFilePath"
                        class="pointer"
                        @click="uploadPhoto"
                      >
                        <button class="btn" type="button">
                          <i class="icon-upload"></i>
                        </button>
                      </li>
                      <li
                        v-else
                        class="pointer"
                        @click="resetPhoto"
                      >
                        <button
                          class="btn"
                          type="button"
                        >
                          <i class="icon-trash"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <label class=" field-name-class w-100">
                {{ $t('views.playground.index.parameters.options.form.availability') }}
              </label>
              <select
                v-model="option.enabled"
                class="form-control background-light-blue-inputs"
                size="sm"
              >
                <option :value="true">
                  {{ $t('general.actions.yes') }}
                </option>
                <option :value="false">
                  {{ $t('general.actions.no') }}
                </option>
              </select>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label class="mt-2 field-name-class w-100">
                {{ $t('views.playground.index.parameters.options.form.price') }}
              </label>
              <b-input-group>
                <b-form-input
                  v-model="option.price"
                  type="number"
                  min="0"
                  size="sm"
                  value="0"
                  autocomplete="off"
                  class="background-light-blue-inputs"
                />
                <b-input-group-append is-text>
                  <div> {{ $currency }}</div>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback
                :state="option.price !== '' && option.price !== null"
              >
                {{ $t('validation.required') }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="8">
          <b-row>
            <b-col cols="6">
              <label class="field-name-class">
                {{ $t('views.playground.index.parameters.options.form.name') }}
              </label>
            </b-col>
            <b-col cols="6">
              <label class="field-name-class">
                {{ $t('views.playground.index.parameters.options.form.label-option') }}
              </label>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <d-text-field
                v-model="option.name"
                :rules="validation.name"
                size="sm"
                :class-name="'background-light-blue-inputs'"
                error-text="validation.text-input-three-characters"
              />
            </b-col>
            <b-col cols="6">
              <d-text-field
                v-model="option.label"
                :rules="validation.label"
                size="sm"
                :class-name="'background-light-blue-inputs'"
                error-text="validation.text-input-three-characters"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <label class="field-name-class w-100">
                {{ $t('views.playground.index.parameters.options.form.description') }}
              </label>
              <b-textarea
                v-model="option.description"
                size="sm"
                class="form-control background-light-blue-inputs"
                rows="8"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-3 d-flex align-items-center">
        <b-col class="mt-4" cols="4">
          <div class="d-flex align-items-center">
            <vs-checkbox
              color="primary"
              v-model="option.quantifiable"
              value="false"
            />
            <p class="simple-label-avenir">
              {{ $t('views.playground.index.parameters.options.form.quantifiable.label') }}
            </p>
          </div>
        </b-col>
        <b-col v-if="option.quantifiable">
          <b-row>
            <b-col cols="6">
              <label class="field-name-class">
                {{ $t('views.playground.index.parameters.options.form.quantifiable.min') }}
              </label>
              <d-text-field
                v-model="option.minQuantity"
                :rules="validation.minQuantity"
                size="sm"
                min="0"
                type="number"
                :class-name="'background-light-blue-inputs'"
                error-text="validation.required"
              />
            </b-col>
            <b-col cols="6">
              <label class="field-name-class">
                {{ $t('views.playground.index.parameters.options.form.quantifiable.max') }}
              </label>
              <d-text-field
                v-model="option.maxQuantity"
                :rules="validation.maxQuantity"
                :min="parseInt(option.minQuantity)"
                :class-name="!option.minQuantity || option.minQuantity === '' ? 'disabled background-light-blue-inputs' : 'background-light-blue-inputs'"
                :readonly="!option.minQuantity || option.minQuantity === ''"
                type="number"
                size="sm"
                error-text="validation.required"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col align="right">
          <d-button
            v-if="option.id"
            text="general.actions.cancel"
            class="width-max-content d-btn-sm btn d-btn-default font-text-title mr-2"
            @on:button-click="onCancel"
          />

          <d-button
            :text="option.id ? 'general.actions.edit' : 'views.playground.index.parameters.options.new'"
            class="width-max-content d-btn-sm btn d-btn-danger font-text-title"
            @on:button-click="onValidate"
          />
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script>
import Option from "@/classes/doinsport/option";
import {OPTION_URI, postOption, putOption} from "@api/doinsport/services/option/option.api";
import {deleteOptionPhoto, OPTION_PHOTO} from "@api/doinsport/services/option/option-photo.api";
import {fromIriReferenceToId} from "@/utils/form";
import {_delete} from "@api/doinsport/services/httpService";

export default {
  data: () => ({
    isQuantifiable: true,
    fileToken: null,
    validation: require('@validation/entities/doinsport/Option.json')
  }),
  props: {
    option: {
      type: Object,
      default: () => new Option()
    }
  },
  computed: {
    svgFilePath() {
      return require('@/assets/icons/playground/new/step-1/upload-image.svg');
    },
    optionPhoto() {
      if (this.option.id) {
        return this.option.photo ? this.$filePath(this.option.photo.contentUrl) : this.svgFilePath;
      } else {
        return this.fileToken ? this.fileToken : this.svgFilePath;
      }
    }
  },
  methods: {
    uploadPhoto() {
      if (this.option.id === null) {

        this.$upload(this.uploaded,
          {
            entry: 'option',
            url: null,
            target: 'optionPhoto'
          })
        ;
      } else {
        this.$upload(this.uploaded, {entry: 'option', url: OPTION_URI + '/' + this.option.id, target: 'optionPhoto'});
      }
    },
    uploaded(file) {
      if (this.option.id === null) {
        this.fileToken = URL.createObjectURL(file);
        this.option.photo = file;
      } else {
        this.fileToken = this.$filePath(file.contentUrl);
        this.option.photo = file;
      }
    },
    resetPhoto() {
      this.fileToken = null;
      if (this.option.id) {
        _delete(this.option.photo['@id']).then(() => this.option.photo = null);
      } else {
        this.option.photo = null;
      }
    },
    onCancel() {
      this.$emit('reset:option-form');
      this.resetObserver();
    },
    onValidate() {
      try {
        this.$refs.optionObserver.validate().then((valid) => {
          if (valid) {
            const serializedOption = new Option(this.option, {serialize: true});

            if (!serializedOption.quantifiable) {
              serializedOption.minQuantity = null;
              serializedOption.maxQuantity = null;
            }

            delete serializedOption.photo;

            this.getOptionRequest(serializedOption)
              .then((response) => {
                if (null !== this.option.photo && this.option.id === null) {
                  const payload = {entry: 'option', url: response.data['@id'], target: 'optionPhoto'};
                  this.$uploadFile(this.option.photo, payload);
                }

                this.$emit('on:option-added');
                this.$vs.notify({
                  time: 5000,
                  title: this.$t('general.actions.notification'),
                  text: this.$t('views.playground.index.parameters.options.added'),
                  color: 'success',
                  position: 'top-right'
                });
              }).finally(
              () => {
                this.$emit('reset:option-form');
                this.resetObserver();
              })
            ;
          }
        })
      } catch (err) {
      }
    },
    getOptionRequest(option) {
      return option.id ? putOption(option) : postOption(option);
    },
    resetObserver() {
      this.$refs.optionObserver.reset();
    }
  },
  created() {
    this.$vs.theme({
      primary: 'rgb(0, 165, 216)'
    })
  },
}
</script>
<style lang="scss" scoped>
/deep/ .input-group-text {
  line-height: 0;
}

/deep/ .vs-checkbox--input:checked + .vs-checkbox .vs-icon {
  font-family: 'Material Icons';
}

.option-img {
  width: 280px;
  height: 110px;
  object-fit: cover;
}

.product-box .product-img {
  .product-hover {
    width: 280px;
    height: 110px;
  }
}
</style>
